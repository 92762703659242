import { useState } from "react"
import { useSelector } from "react-redux"
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { GiHamburgerMenu, GiShoppingCart } from 'react-icons/gi'
import { 
  Nav, 
  NavBrand,
  NavButtons,
  NavList,
  NavListItems,
  NavLink,
  NavMenuButton
} from './navbar.elements'
import { Badge } from '../../styles/global.elements'
import NavDropdown from './navDropdown'
import LogoutButton from './logoutButton'
import device from '../../styles/device'

const Separator = styled.div`
  flex: 6;

  @media ${device.xl} {
    display: none;
  }
`

const Navbar = () => {
  const isLogged = useSelector(state => {
    if (state.root.auth && 'token' in state.root.auth) {
      return true
    }
    return false
  })
  const cartLength = useSelector(state => state.root.cart ? state.root.cart.length : 0)
  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(!active)
  }

  return (
    <Nav>
      <NavBrand>
        <Link href="/">
          <a>
            <Image
              src="/logo.png"
              alt="CBRPV Logo"
              layout="responsive"
              width={300}
              height={88}
            />
          </a>
        </Link>
      </NavBrand>
      <Separator></Separator>
      <NavMenuButton onClick={handleClick}>
        <GiHamburgerMenu />
      </NavMenuButton>
      <NavButtons active={active}>
        <NavList>
          <NavDropdown href="/queries" text="Consultas">
            <NavLink href="/queries/indexQuery">
              Consulta índices
            </NavLink>
            <NavLink href="/queries/scanRegistry">
              Registros escaneados
            </NavLink>
            <NavLink href="/queries/coverStatus">
              Estado de carátula
            </NavLink>
            <NavLink href="/queries/coverSearch">
              Búsqueda de carátula
            </NavLink>
            {isLogged && (
              <NavLink href="/queries/blueprintsSearch">
                Búsqueda de planos
              </NavLink>
            )}
          </NavDropdown>
          <NavDropdown href="/requests" text="Trámites en línea">
            <NavLink href="/requests/copyCertificateRequest">
              Copias o certificados
            </NavLink>
            <NavLink href="/requests/unarchivesRequest">
              Solicitud de desarchivos
            </NavLink>
            <NavLink href="/requests/blueprintsDocsRequest">
              Copias de planos o documentos
            </NavLink>
            <NavLink href="/requests/protocolsRequest">
              Copias de escrituras públicas
            </NavLink>
            <NavLink href="/requests/deedRequest">
              Solicitud de ingreso de escrituras
            </NavLink>
            <NavLink href="/requests/uprisingRequest">
              Solicitud de alzamientos
            </NavLink>
          </NavDropdown>
          <NavDropdown href="/info" text="Información">
            <NavLink href="/info/contact">
              Contacto
            </NavLink>
            <NavLink href="/info/documentTypes">
              Tipos de documentos
            </NavLink>
            <NavLink href="/info/faq">
              Preguntas frecuentes
            </NavLink>
          </NavDropdown>
          <NavListItems>
            <NavLink href="https://www.fojas.cl">Verificación firma electrónica</NavLink>
          </NavListItems>
          {!isLogged && (
            <>
              <NavListItems>
                <NavLink href="/auth/login">Iniciar sesión</NavLink>
              </NavListItems>
              <NavListItems>
                <NavLink href="/auth/register">Crear cuenta</NavLink>
              </NavListItems>
            </>
          )}
          {isLogged && (
            <>
              <NavListItems>
                <NavLink href="/profile">Perfil</NavLink>
              </NavListItems>
              <NavListItems>
                <LogoutButton />
              </NavListItems>
            </>
          )}          
          <NavDropdown href="#" text="Accesos">            
            <NavListItems>
              <NavLink href="https://notarias.conservadorpvaras.cl/login">Notaría</NavLink>
            </NavListItems>
            <NavListItems>
              <NavLink href="https://servicios.conservadorpvaras.cl">D.G.A.</NavLink>
            </NavListItems>
            <NavListItems>
              <NavLink href="https://servicios.conservadorpvaras.cl">Bienes Nacionales</NavLink>
            </NavListItems>
            <NavListItems>
              <NavLink href="https://servicios.conservadorpvaras.cl">SII</NavLink>
            </NavListItems>
            <NavListItems>
              <NavLink href="https://servicios.conservadorpvaras.cl">C.D.E.</NavLink>
            </NavListItems>
            <NavListItems>
              <NavLink href="https://servicios.conservadorpvaras.cl">S.A.G.</NavLink>
            </NavListItems>
          </NavDropdown>
          {cartLength > 0 && (
            <NavListItems>
              <NavLink href="/cart">
                <GiShoppingCart size={32} />
                <Badge>{cartLength}</Badge>
              </NavLink>
            </NavListItems>
          )}
        </NavList>
      </NavButtons>
    </Nav>
  )
}
 
export default Navbar